
import {defer as observableDefer, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AssessmentRequestListItemModel, ARQuickAdditionalServiceModel} from '../assessment-requests/assessment-request-list-item.model';
import {EditAssessmentRequestModel} from '../assessment-requests/edit-assessment-request.model';
import {AuthHttpService} from '../auth/auth-http.service';
import {IViewAssessmentRequest} from '../assessment-requests/view-assessment-request.interface';
import {IEditAssessmentRequestModel} from '../assessment-requests/edit-assessment-request.interface';
import {AssessmentRequestStatus} from './enums/assessment-request-status.enum';
import {IAssessmentTestingScheduleModel} from './interfaces/assessment-testing-schedule.interface';
import {IAdditionalServiceAttachmentModel, IAssessmentLevelAttachmentModel} from './interfaces/attachment-base.interface';
import {IHiringOutcomeUpdate} from './interfaces/hiring-outcome-update.interface';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';

@Injectable()
export class AssessmentRequestService extends ServiceBase {
  public static ApiUrl = 'api/assessment-request';
  private static AssessmentLevelAttachmentApiUrl = 'api/assessment-level-attachment';
  private static AdditionalServiceAttachmentApiUrl = 'api/additional-service-attachment';
  private data$: Observable<AssessmentRequestListItemModel[]>;
  private cacheDuration: number = 1000 * 30;

  public refreshListSubject = new Subject<boolean>();
  public onRefreshList$ = this.refreshListSubject.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  public refreshListView() {
    this.refreshListSubject.next(true);
  }

  public getAll(statuses: AssessmentRequestStatus[], fromPage?: string): Observable<AssessmentRequestListItemModel[]> {
    this.clearCache();
    if (!this.data$) {
      this.data$ = observableDefer(() => {
        let url = AssessmentRequestService.ApiUrl;
        let isFirst = true;

        if (statuses && statuses.length) {
          statuses.forEach((status) => {
            url += (isFirst ? '?' : '&') + 'status=' + status;
            isFirst = false;
          });
        }
        if (fromPage) {
          url += (isFirst ? '?' : '&') + 'fromPage=' + fromPage;
        }

        return this.http.get<AssessmentRequestListItemModel[]>(url).pipe(
          map(assessments => assessments.map(assessment => {
            return new AssessmentRequestListItemModel(assessment);
          }))
        );
      });
    }

    return this.data$;
  }

  public get (id: number): Observable<EditAssessmentRequestModel> {
    const url = `${AssessmentRequestService.ApiUrl}/${id}`;

    return this.http.get<IEditAssessmentRequestModel>(url).pipe(map(assessment => {
      return new EditAssessmentRequestModel(assessment as IEditAssessmentRequestModel);
    }));

  }

  public getView(id: number): Observable<IViewAssessmentRequest> {
    const url = `${AssessmentRequestService.ApiUrl}/view?id=${id}`;

    return this.http.get<IViewAssessmentRequest>(url);
  }

  public getForAdditionalService(id: number): Observable<EditAssessmentRequestModel> {
    const url = `${AssessmentRequestService.ApiUrl}/additional-service/${id}`;

    return this.http.get<IEditAssessmentRequestModel>(url).pipe(map(assessment => {
      return new EditAssessmentRequestModel(assessment as IEditAssessmentRequestModel);
    }));

  }

  public save(assessmentRequest: IEditAssessmentRequestModel): Observable<HttpResponse<Object>> {
    return (assessmentRequest.assessmentRequestId)
      ? this.update(assessmentRequest)
      : this.add(assessmentRequest);
  }

  public quickAdditionalService(input: ARQuickAdditionalServiceModel[]): Observable<HttpResponse<Object>> {
    const json = JSON.stringify(input);
    return this.http.post<HttpResponse<Object>>(AssessmentRequestService.ApiUrl + "/quick-additional-service", json, {headers: this.jsonHeaders, observe: "response" });
  }

  public send360InfoNotification(): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/send-360-info-notification`
    return this.http.post<HttpResponse<Object>>(url, { observe: "response" });
  }

  public saveProgress(assessmentRequest: IEditAssessmentRequestModel): Observable<HttpResponse<Object>> {
    return (assessmentRequest.assessmentRequestId)
      ? this.updateProgress(assessmentRequest)
      : this.addProgress(assessmentRequest);
  }

  public delete(id: number): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/${id}`;
    return this.http.delete<HttpResponse<Object>>(url, { observe: "response" });
  }

  public purgeAttachments(assessmentRequestId: number): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/purge-attachments`;
    return this.http.post<HttpResponse<Object>>(url, assessmentRequestId, {headers: this.jsonHeaders, observe: "response" });
  }

  public getAdditionalService(id: number): Observable<EditAssessmentRequestModel> {
    return this.getForAdditionalService(id).pipe(
      map((model: EditAssessmentRequestModel) => {
        // create a new assessment request that is the child of the 'id' assessment request
        model.parentAssessmentRequestId = model.assessmentRequestId;
        model.assessmentRequestId = null;
        model.assessmentId = null;
        model.assessmentLevelReferenceId = model.assessmentLevelReferenceId;
        model.orginalAdditionalServiceReferenceIds = model.additionalServiceReferenceIds;
        model.additionalServiceReferenceIds = [];
        model.serviceNotes = null;

        return model;
      })
    );
  }

  public assignConsultant(assessmentRequestId: number, consultantId: number): Observable<HttpResponse<Object>> {
    const assign = {assessmentRequestId: assessmentRequestId, consultantId: consultantId};
    const json = JSON.stringify(assign);
    const url = AssessmentRequestService.ApiUrl + '/assign';
    return this.http.put<HttpResponse<Object>>(url, json, {headers: this.jsonHeaders, observe: "response" });
  }

  public removeAssignedConsultant(assessmentRequestId: number, consultantId: number): Observable<HttpResponse<Object>> {
    const assign = {assessmentRequestId: assessmentRequestId, consultantId: consultantId};
    const json = JSON.stringify(assign);
    const url = AssessmentRequestService.ApiUrl + '/remove';
    return this.http.post(url, json, {headers: this.jsonHeaders, observe: "response" });
  }

  public getTestingSchedule(id: number): Observable<IAssessmentTestingScheduleModel> {
    const url = `${AssessmentRequestService.ApiUrl}/${id}/testing-schedule`;
    return this.http.get<IAssessmentTestingScheduleModel>(url);
  }

  public saveTestingSchedule(testingSchedule: IAssessmentTestingScheduleModel): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/${testingSchedule.assessmentRequestId}/testing-schedule`;
    const json = JSON.stringify(testingSchedule);
    return this.http.put<HttpResponse<Object>>(url, json, {headers: this.jsonHeaders, observe: "response" });
  }

  public saveHiringOutcomeModal(cachedOutcomeUpdates: IHiringOutcomeUpdate[]): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/save-hiring-modal`;
    const json = JSON.stringify(cachedOutcomeUpdates);
    return this.http.put<HttpResponse<Object>>(url, json, {headers: this.jsonHeaders, observe: "response" });
  }

  public getAdditionalServiceAttachments(id: number): Observable<IAdditionalServiceAttachmentModel[]> {
    const url = `${AssessmentRequestService.ApiUrl}/${id}/additional-service-attachments`;
    return this.http.get<IAdditionalServiceAttachmentModel[]>(url);
  }

  public saveVerbalDelivered(additionalServiceId: number): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/additional-service-verbal-delivered/${additionalServiceId}`
    return this.http.post<HttpResponse<Object>>(url, { observe: "response" });
  }

  public saveVerbalNotDelivered(additionalServiceId: number): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/additional-service-verbal-not-delivered/${additionalServiceId}`
    return this.http.post<HttpResponse<Object>>(url, { observe: "response" });
  }


  public saveAdditionalServiceAttachment(assessmentRequestId: number, additionalServiceId: number, additionalServiceTypeId: number, description: string, file: File): Observable<HttpResponse<Object>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('additionalServiceTypeId', additionalServiceTypeId.toString());
    formData.append('description', description);

    var headers = new HttpHeaders({'Accept': 'application/json'})

    const url = `${AssessmentRequestService.ApiUrl}/${assessmentRequestId}/additional-service-attachment/${additionalServiceId}`;
    return this.http.post<HttpResponse<Object>>(url, formData, {headers: headers, observe: "response" });
  }

  public deleteAdditionalServiceAttachment(additionalServiceAttachmentId: number): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.AdditionalServiceAttachmentApiUrl}/${additionalServiceAttachmentId}`;
    return this.http.delete<HttpResponse<Object>>(url, { observe: "response" });
  }

  public getAssessmentLevelAttachments(id: number): Observable<IAssessmentLevelAttachmentModel[]> {
    const url = `${AssessmentRequestService.ApiUrl}/${id}/assessment-level-attachment`;
    return this.http.get<IAssessmentLevelAttachmentModel[]>(url);
  }

  public saveAssessmentLevelAttachment(assessmentRequestId: number, assessmentLevelId: number, assessmentLevelTypeId: number, description: string, file: File): Observable<HttpResponse<Object>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('assessmentLevelTypeId', assessmentLevelTypeId.toString());
    formData.append('description', description);

    var headers = new HttpHeaders({'Accept': 'application/json'})
   
    const url = `${AssessmentRequestService.ApiUrl}/${assessmentRequestId}/assessment-level-attachment/${assessmentLevelId}`;
    return this.http.post<HttpResponse<Object>>(url, formData, {headers: headers, observe: "response" });
  }

  public deleteAssessmentLevelAttachment(assessmentLevelAttachmentId: number): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.AssessmentLevelAttachmentApiUrl}/${assessmentLevelAttachmentId}`;
    return this.http.delete<HttpResponse<Object>>(url);
  }

  public setStatus(id: number, status: AssessmentRequestStatus): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/${id}/status`;
    const json = JSON.stringify({status: status});
    return this.http.put<HttpResponse<Object>>(url, json, {headers: this.jsonHeaders, observe: "response" });
  }

  public clearCache(): void {
    this.data$ = null;
  }
  
  private add(assessmentRequest: IEditAssessmentRequestModel): Observable<HttpResponse<Object>> {
    const json = JSON.stringify(assessmentRequest);
    return this.http.post<HttpResponse<Object>>(AssessmentRequestService.ApiUrl, json, {headers: this.jsonHeaders, observe: "response" });
  }

  private update(assessmentRequest: IEditAssessmentRequestModel): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/${assessmentRequest.assessmentRequestId}`;
    const json = JSON.stringify(assessmentRequest);
    return this.http.put<HttpResponse<Object>>(url, json, {headers: this.jsonHeaders, observe: "response" });
  }

  private addProgress(assessmentRequest: IEditAssessmentRequestModel): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/progress`;
    const json = JSON.stringify(assessmentRequest);
    return this.http.post<HttpResponse<Object>>(url, json, {headers: this.jsonHeaders, observe: "response" });
  }

  private updateProgress(assessmentRequest: IEditAssessmentRequestModel): Observable<HttpResponse<Object>> {
    const url = `${AssessmentRequestService.ApiUrl}/progress/${assessmentRequest.assessmentRequestId}`;
    const json = JSON.stringify(assessmentRequest);
    return this.http.put<HttpResponse<Object>>(url, json, {headers: this.jsonHeaders, observe: "response" });
  }
}
